<template>
  <div>
    <v-row>
      <v-col lg="12">
        <farm-lot :parentID="FagID" :isAdmin="true"  v-on:rowSelectFarmLot="getFarmLotID" @rowSelected="rowSelected($event)"></farm-lot>
      </v-col>
      <v-col lg="12">
        <certification :farmLotID="farmLotID" :lots="lots" :height="200" :isAdmin="true"></certification>
      </v-col>
      
     
    </v-row>
  </div>
</template>
<script>
import FarmLot from "@/views/TechnicalAssistance/Certification/FarmLot";
import Certification from "@/views/TechnicalAssistance/Certification/Certification";
/* import Producer from "@/views/TechnicalAssistance/Certification/Producer"; */

export default {
  components: { FarmLot, Certification},
  data(){
    return {
      parentID: 0,
      FltID: 0,
      farmLotID: 0,
      lots: []
    };
  },
  methods: {
    rowSelectedFarms(rows) {
      this.parentID = rows[0].FltID;
    },
    getFarmLotID(farmLotID){
      this.farmLotID = farmLotID;
    }
  },
};
</script>
